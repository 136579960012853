import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
// import OrderListView from "@/views/OrderListView.vue";
// import {showToast} from "vant";


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/complete',
    name: 'complete',
    component: () => import('../views/CompleteView.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/OderDetailView.vue')
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('../views/OrderListView.vue'),
  },
  {
    path: '/person',
    name: 'person',
    component: () => import( '../views/PersonView.vue')
  },

  {
    path: '/introduce',
    name: 'introduce',
    component: () => import( '../views/IntroduceView.vue')
  },
  {
    path: '/logoff',
    name: 'logoff',
    component: () => import( '../views/LogoffView.vue')
  },
  {
    path: '/reLogin',
    name: 'reLogin',
    component: () => import( '../views/ReLogin.vue')
  },
  {
    path: '/pdf',
    name: 'pdf',
    component: () => import( '../views/pdfpreView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
