<template>
  <div class="mt15 pt10" style="width: calc(100% - 40px);margin:0 auto;background:#fff;border-radius: 0.4rem;" >
    <div :class="['ml25',$route.path==='/list'||$route.path==='/person'?'':'pl20']"
         :style="['font-size: 0.8rem',$route.path==='/list'||$route.path==='/person'?'':'text-align:left']">绑定手机号码</div>
    <div class="mt10 pt10 pb5" style="border-radius: 6px;background: #fff;">
      <van-form @submit="onSubmit" style="background: #fff;">
        <van-cell-group inset>
          <van-field
              label-width="36px"
              style="padding-top: 15px;padding-bottom: 15px;"
              v-model="username"
              name="tel"
              placeholder="请输入手机号码"
              type="tel"
              clearable
              :rules="[{ required: true, message: '请输入手机号码' }]"
          >
            <template #label>
              <span>86</span><van-icon name="arrow" />
            </template>
          </van-field>
          <van-field
              v-model="password"
              type="number"
              name="number"
              placeholder="请输入验证码"
              clearable
              :rules="[{ required: true, message: '请输入验证码' }]"
          >
            <template #button>
              <van-button v-if="getCode||!time" size="small" type="primary" @click="sendCode()" :disabled="username.length!==11">
                <span>获取验证码</span>
              </van-button>

              <van-button v-else size="small" type="primary">
                <van-count-down :time="time" @finish="onFinish" ref="countDown">
                  <template #default="timeData">
                    <span style="color: #fff;" @click="start">{{ timeData.seconds }}秒重新获取</span>
                  </template>
                </van-count-down>

              </van-button>
            </template>
          </van-field>
        </van-cell-group>
        <div style="margin:40px 16px 0 16px;">
          <van-button  block type="primary" native-type="submit">绑定</van-button>
        </div>
      </van-form>

      <div style="width: 86%;margin: 20px auto;line-height: 24px;">
        <div style="font-size: 14px;">为了更好地为您服务，需要绑定您的手机号码，验证即表示您同意
          <a class="a_link black" href="#" @click="UserAgreementShow = true">《用户协议》</a>
          <a class="a_link black pl10" href="#" @click="PrivacyPolicyShow = true">《隐私政策》</a>
        </div>
      </div>
    </div>
  </div>
  <van-overlay :show="UserAgreementShow" :lock-scroll="false">
    <div class="wrapper" @click.stop>
      <div class="block">
        <h3>用户协议</h3>
        <div class="text-left agreement" style="padding:0 20px;height:calc(100% - 120px);overflow-y: auto;">
          <p>
            1．委托人应当按填写要求填写委托单，并对所提供内容的真实性及可靠性负责。委托单通过审核并被受理后，不能随意更改。
          </p>
          <p>
            2．委托人需要将委托单中，用红色星号标识为必填内容的栏目填写完整。在查新机构觉得有必要的情况下，委托人需要根据项目内容，真实、完整、准确地向查新机构提供查新项目的背景技术和主要科学技术资料，如委托方拒绝提供，将退回该查新委托，如提供资料不实，造成查新失误，委托人须负全部责任。
          </p>
          <p>
            3．委托人提交科技查新委托单后，有义务配合查新人员工作，介绍所委托项目的技术内容，确定检索主题；在查新过程中，查新人员提出不明事宜时，委托方有义务给予及时解答。
          </p>
          <p>
            4．委托人不能将两或两个以上不同主题（即不具备单一性）的课题作为一个项目申请。对于不具有单一性的含有子课题的复杂课题，委托人应进行分案查新，特别的，委托人不愿意分案的情况下，应该与查新机构协商查新费用。
          </p>
          <p>
            5．根据《西南信息中心查新中心质量管理规范》要求，我中心出具的查新报告，不对时间先后进行查证，不会给予指定日期的时间范围性结论，只能按委托日进行检索。即不对比指定日以前，查新点是否在公开出版物上发表过以及本项目是否早于公开文献的日期。
          </p>
          <p>
            6．按照国家知识产权局相关要求，科技查新报告不得用于专利纠纷、司法诉讼、争议仲裁等司法程序。委托人隐瞒相关用途进行委托，所产生的后果由查新委托人自行承担。
          </p>
          <p>
            7. 委托人不得篡改或伪造查新机构出具的科技查新报告（含纸质报告、报告电子档和扫描件），否则，由此产生的一切后果由委托人承担。
          </p>
          <p>
            8．按相关要求需要保密的项目，请勿进行在线委托。
          </p>
          <p>
            9．项目查新实行先付费制度，委托人与查新机构确认查新内容后按收费标准办理缴款手续后，查新委托方被受理，遇有特殊情况，如加急等，应根据具体收费标准交纳加急费。
          </p>
          <p>
            10．委托人不得干涉查新活动，不得向查新人员施加任何倾向性影响。
          </p>
          <p>
            11．审核通过并进入查新流程的项目，若委托人提出取消委托，查新机构会根据情况决定是否退款，已经开始进行检索的项目，将不予退款。
          </p>
          <p>
            12．查新结论与费用无关，查新结论为“已见文献报道”或“不具有新颖性”的，不予退款。
          </p>
          <p>
            13．科技查新报告完成后，委托人不得随意要求提出修改。
          </p>
          <p>
            14．科技查新报告不予补查，只能重新查新。
          </p>
          <p>
            15．查新周期：国内查新，自收到可以受理的委托单并付款之后的第二个工作日起算，国内查新5个工作日完成查新报告，国内外查新，自委托生效日起8个工作日完成查新报告。业务繁忙期，我中心保留对时间进行调整的权利。
          </p>
          <p>
            16．因网络故障、停电及其他不可抗力因素，难以按约定时间提交查新报告的，双方协商报告的完成时间。
          </p>
          <p>
            17．关于发票信息的特别提示：（1）我们根据您填写的信息开具发票，如因填写失误造成发票开具错误，我们不会受理退换发票以及重新开具发票的要求，请务必认真校对所填开票信息；（2）根据《国家税务总局关于增值税发票开具有关问题的公告》（国家税务总局公告2017年第16号）的要求，自2017年7月1日起，购买方为企业的，索取增值税普通发票时，应向销售方提供纳税人识别号或统一社会信用代码。（3）增值税一般纳税人才能开具增值税专用发票，请向您单位的财务人员索取最新的开票信息。（4）开具增值税专用发票，我们推荐您采用对公转账的方式汇款，发票按照汇款单位名称照实开具；特殊情况下，当您使用私人账户付款并要求开具增值税专用发票的，请在系统的账户设置下属的发票信息栏目，下载增值税专用发票授权委托书，填写、签字并加盖公章之后提交给我们。(5)我单位普通发票实行电子发票，请根据相关指引进行申请和下载。
          </p>
          <p>
            18.个人信息保护及授权
            (一)您知悉并同意：为方便您使用本网站相关服务，本网站将存储您在使用时的必要信息，包括但不限于您的真实姓名、单位、地址、联系方式等。除法律法规规定的情形外，未经您的许可我们不会向第三方公开、透露您的个人信息。建议您完整地阅读隐私政策(https://www.chaxin.org.cn/page/privacy_policy) ，以帮助您更好地保护您的隐私权。
            (二)您充分理解并同意：通过邮件、短信等平台渠道形式接收相关订单以及发票信息。为配合行政监管机关、司法机关执行工作，在法律规定范围内我们有权向上述行政、司法机关提供您在使用本网站时所储存的相关信息。
          </p>


        </div>
        <van-button class="mt10" plain type="primary"  @click="UserAgreementShow = false">我已阅读</van-button>
      </div>
    </div>
  </van-overlay>

  <van-overlay :show="PrivacyPolicyShow" :lock-scroll="false">
    <div class="wrapper" @click.stop>
      <div class="block">
        <h3>隐私政策</h3>
        <div class="text-left agreement" style="padding:0 20px;height:calc(100% - 120px);overflow-y: auto;">
          <p>
            <span class="fw fz14">一、信息的收集和使用</span><br>
            <span class="mt5 blockDiv">为向您提供信息咨询服务，我们的基本功能包括账号注册登录、订单填写以及管理、支付、发票管理、个人信息管理、消息通知、客服。在您使用上述功能过程中，我们会收集使用下列与您有关的信息，如您不提供相应信息，您将无法正常使用我们的服务。</span>
          </p>
          <p>
            <span class="fw fz12">（一）账号注册登录</span><br>
            <span class="mt5 blockDiv">当您注册使用我们的服务时，您需要向我们提供手机号码，我们将通过发送短信验证码来验证您的身份是否有效。您可以修改和补充您的真实姓名、单位名称信息，这些信息均属于您的“账号信息”。注册完成后，您可以选择使用账号信息或者授权我们获取您的第三方账号信息进行登录，若您不提供这类信息，您将无法登录和使用我们的服务，此时您可使用浏览功能，我们将不会收集您的上述个人信息。</span>
          </p>
          <p>
            <span class="fw fz12">（二）下单及订单管理</span><br>
            <span class="mt5 blockDiv">当您填写完订单并进行在线提交时，我们会生成您的订单。您需要在订单中至少填写您的委托单位、收件人姓名、收件地址、电子邮箱以及手机号码，同时该订单中会载明订单号、服务信息、下单时间、预估的服务费金额及支付方式、发票抬头、纳税人识别号以及接收发票的邮箱。</span>
            <span class="mt5 blockDiv">上述所有信息构成您的“订单信息”，我们将使用您的订单信息来（包括与为您提供上述服务的第三方）进行您的身份核验、确定交易、支付结算、完成配送、为您查询订单以及提供客服咨询与售后服务。</span>
          </p>
          <p>
            <span class="fw fz12">（三）支付功能</span><br>
            <span class="mt5 blockDiv">在您下单后，您可以选择线下支付以及在线支付（包括微信和支付宝）进行付款。我们需要将您的订单号、交易金额信息与这些支付机构共享以完成下单支付功能。在您使用支付功能时，支付机构会通过其页面收集或验证您的银行卡（含储蓄卡、信用卡）及其他支付必要信息，以实现其确认您的支付指令并完成支付，具体适用各支付机构相应的隐私政策。</span>
            <span class="mt5 blockDiv">为了解您的支付进度及状态，我们会向您所选择的支付机构收集相应的支付信息，包括支付时间、支付方式、支付金额。</span>
          </p>
          <p>
            <span class="fw fz12">（四）服务交付</span><br>
            <span class="mt5 blockDiv">选择了纸质报告的客户，当您的订单验收完成，与我们合作的配送公司将为您完成订单的交付。您知晓并同意，与我们合作的配送公司会在上述环节内使用您的订单信息以保证您的订购的服务能够安全送达。</span>
          </p>
          <p>
            <span class="fw fz12">（五）客服</span><br>
            <span class="mt5 blockDiv">当您需要我们提供与您订单信息相关的咨询服务时，我们将会查询您的订单信息和账号信息。另外，您在申请退款时，我们需要收集您的联系人、联系方式以及地址，以保障完成相应的售后服务。</span>
          </p>
          <p>
            <span class="fw fz12">（五）客服</span><br>
            <span class="mt5 blockDiv">当您需要我们提供与您订单信息相关的咨询服务时，我们将会查询您的订单信息和账号信息。另外，您在申请退款时，我们需要收集您的联系人、联系方式以及地址，以保障完成相应的售后服务。</span>
          </p>
          <p>
            <span class="fw fz12">（六）保障交易安全</span><br>
            <span class="mt5 blockDiv">为保障您使用我们的服务时系统的稳定性和安全性，防止您的个人信息被非法获取，更准确地预防欺诈和保护账号安全，识别是否为真实自然人用户，我们需要收集您的手机号码。您可以出于自愿的原则提供真实姓名、单位名称、性别、微信账号给我们以便于进一步识别用户是否为真实自然人，我们不会以任何方式主动要求您提供这些信息。我们也会收集您的设备信息（设备型号、浏览器版本、IP地址、网络状况）用于对系统问题进行分析、统计流量并排查可能存在的风险，在您选择向我们发送异常信息时予以排查。如果您不提供上述信息，我们则无法在您使用服务过程中对您的服务及账号安全进行保护。</span>
          </p>
          <p>
            <span class="fw fz12">（七）Cookies的使用</span><br>
            <span class="mt5 blockDiv">在您使用我们的服务过程中，我们会在您的计算机或移动设备上发送一个或多个名为Cookies的小数据文件，目的是为了简化您重复登录的步骤、存储您订单中的信息、帮助判断您的登录状态以及账号或数据安全。</span>
            <span class="mt5 blockDiv">您可以清除计算机上保存的所有 Cookies，大部分网络浏览器会自动接受Cookies，但您通常可根据自己的需要来修改浏览器的设置以拒绝 Cookies；另外，您也可以清除软件内保存的所有Cookies。如果您这么做，您可能需要在每一次访问我们网站时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性有一定影响。</span>
          </p>
          <p>
            <span class="fw fz14">二、个人信息的共享和公开</span><br>
            <span class="fw mt5 blockDiv fz12">（一）共享</span><br>
            <span class="blockDiv">1、我们不会与其他的任何公司、组织和个人共享您的个人信息，但以下情况除外： </span>
            <span class="blockDiv">（1）事先获得您明确的同意或授权； </span>
            <span class="blockDiv">（2）根据法律法规、行政及司法部门强制性要求进行提供；</span>
            <span class="blockDiv">（3）应您需求为您处理您与他人的纠纷或争议。 </span>
            <span class="blockDiv mt5">2、请您注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或第三方的个人信息甚至敏感个人信息，请您共享时谨慎考虑并决定。如涉及第三方个人信息，请您确保您已经取得合法的授权。 </span>
          </p>
          <p>
            <span class="fw fz14">三、个人信息的保护和存储</span><br>
            <span class="fw mt5 blockDiv fz12">（一）我们保护您个人信息的技术与措施</span>
            <span class="blockDiv">我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息：</span>
            <span class="blockDiv fw mt5">1、 数据安全技术措施 </span>
            <span class="blockDiv">我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改，避免数据的损坏或丢失。例如：网络服务采取了传输层安全协议加密技术，通过https方式提供浏览服务，确保用户数据在传输过程中的安全；在个人信息处理时，我们会采用包括内容替换、SHA256、MD5在内多种数据脱敏技术增强个人信息在使用中安全性。</span>
            <span class="blockDiv fw  mt5">2、为保护个人信息采取的其他安全措施</span>
            <span class="blockDiv">我们采用“阿里云”作为存储您个人信息的底层云存储服务，“阿里云”通过了公安部网络安全等级保护2.0认证，以“增强”级安全能力通过了中央网信办云计算安全评估，同时还获得了ISO27001、ISO27017、ISO 27701认证。我们还会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</span>
            <span class="blockDiv mt5 fw">3、安全事件处置 </span>
            <span class="blockDiv">为应对个人信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。</span>
            <span class="blockDiv">一旦发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。同时，我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</span>
            <span class="fw mt10 blockDiv fz12">（二）您个人信息的保存</span>
            <span class="blockDiv mt5">1、您的个人信息将存储于中华人民共和国境内。如您使用跨境交易服务，且需要向境外传输您的个人信息完成交易的，我们会单独获取您的授权同意并要求接收方按照双方签署的数据保护协议、本隐私政策以及相关法律法规要求来处理您的个人信息。 </span>
            <span class="blockDiv mt5">2、我们有权根据实际情况，在法律规定范围内自行决定单个用户在本网站及服务中数据的最长储存期限以及用户日志的储存期限，并在服务器上为其分配数据最大存储空间等。</span>
          </p>
          <p>
            <span class="fw fz14">四、个人信息的管理</span><br>
            <span class="mt5 blockDiv fz12">我们非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：</span>
            <span class="blockDiv fw mt5">1、修改您的个人信息</span>
            <span class="blockDiv">除法律法规规定外，您有权随时访问和更正您的个人信息，具体包括：</span>
            <span class="blockDiv mt5">（1）账号信息</span>
            <span class="blockDiv">进入网站-点击“个人中心”菜单中“个人信息”。</span>
            <span class="blockDiv mt5">（2）收件信息 </span>
            <span class="blockDiv">进入网站-点击“个人中心”菜单中“收件地址”。</span>
            <span class="blockDiv mt5">（3）订单信息</span>
            <span class="blockDiv">进入网站-点击“订单中心”页面。</span>
            <span class="blockDiv mt5">（4）发票信息</span>
            <span class="blockDiv">进入网站-点击“发票管理”菜单中的“发票信息”。</span>
            <span class="blockDiv fw mt5">2、注销账号</span>
            <span class="blockDiv">您可以通过以下路径注销您的账号信息：</span>
            <span class="blockDiv">进入网站 “个人中心”，进入菜单“个人信息”，点击页面中的“注销账号”按钮完成注销。</span>
            <span class="blockDiv">请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。</span>
          </p>
          <p>
            <span class="fw fz14">五、政策的更新</span><br>
            <span class="blockDiv mt5">为给您提供更好的服务以及随着我们业务的发展，本隐私政策也会随之更新。请您访问网站以便及时了解最新的隐私政策。</span>
          </p>
          <p>
            <span class="fw fz14">六、联系我们</span><br>
            <span class="blockDiv mt5">如您对本隐私政策或您个人信息的相关事宜或者投诉、举报、有任何问题、意见或建议，请通过以下方式与我们联系。</span>
            <span class="blockDiv">联系电话：400-900-1306</span>
            <span class="blockDiv">联系地址：重庆市渝北区黄山大道中段67号2幢10-4</span>
          </p>
        </div>
        <van-button class="mt10" plain type="primary"  @click="PrivacyPolicyShow = false">我已知悉</van-button>
      </div>
    </div>
  </van-overlay>


</template>

<script setup>
import {ref} from "vue";
import {showLoadingToast, showToast, showFailToast} from 'vant';
import {checkSmsCode, getSmsCode} from "../api/login";
import {submitList} from "../api/home";
import {getUser} from "../api/user";
import {jumpTo} from "../api/routerJS";
import router from "@/router";

// eslint-disable-next-line no-undef
let id=defineProps({
  msg:{
    type:Number
  }
})
console.log(id.msg,'000000')

/*onBeforeMount(()=>{
  const params = new URLSearchParams(window.location.search);
  let code = params.get('code');// 这是微信返回的code
  console.log(code)
  let state = params.get('state');// 登录成功后的参数
  console.log(state)
  let item = localStorage.getItem("wxCscyUser");
  let parse = JSON.parse(item);
  console.log(parse)
  let unionid = JSON.parse(item).unionid;
  console.log(unionid)
  let b = !state || state.indexOf("tabbar") === -1;
  console.log(b)
  if (parse && !unionid && b){
    let pathname = window.location.pathname;
    // 未授权，调起授权
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7b7c22d24bb08527&redirect_uri=https://wechat.chaxin.org.cn${pathname}&response_type=code&scope=snsapi_userinfo&state=tabbar234#wechat_redirect&forcePopup=true`
  }
})*/


const username = ref('');
const password = ref('');
const onSubmit = (values) => {
  console.log('submit', values);

  checkSmsCode(username.value,password.value).then(res=>{
    console.log(res)
      if (res.success) {
          // 验证成功了
          getUser().then((reu) => {
              if (reu.success) {
                  localStorage.setItem("wxCscyUser", JSON.stringify(reu.data))

                  // 为了防止异步放到回调中来
                  // 继续提交订单
                  if (id.msg) {
                      submitList(id.msg).then(reb => {
                          if (reb.success) {
                              jumpTo('/complete', id.msg);
                          } else {
                              showFailToast(reb.msg)
                          }
                      }).catch(err => {
                          console.log(err)
                      })
                  } else {
                    router.go(0);
                  }
              }
          })

    }else{
      // 校验失败了
      showFailToast(res.msg)
    }
  }).catch(err=>{
    console.log(err)
  })

};



/**
 * 点击发送验证码
 */
const time = ref(60000);//30 * 60 * 60 * 1000

const getCode=ref(true);
const sendCode=()=>{
  showLoadingToast({
    message: '正在获取验证码',
    forbidClick: true,
    loadingType: 'spinner',
  });
  getSmsCode(username.value).then(res=>{
    time.value=res.left*1000;
    console.log(res)
    if (res.success){
      showToast('验证码已发送');
      getCode.value=false;
      setTimeout(()=>{
        start();
      },200)
    }else{
      showToast(res.msg);
    }
  })

}

const countDown = ref(null);

const start = () => {
  countDown.value.start();
};

const onFinish = () => {
  getCode.value=true;
};


/**
 * 用户协议、隐私政策的显示与隐藏
 * @type {Ref<UnwrapRef<boolean>>}
 */
let UserAgreementShow = ref(false);
let PrivacyPolicyShow=ref(false);


</script>

<style scoped lang="scss">
.a_link:hover{
  text-decoration: underline;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 90%;
  //aspect-ratio: 1/1.8;
  height: calc(100vh - 2rem);
  background: #fff;
}
.blockDiv{display: block;}

</style>
