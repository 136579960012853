import request from '@/request/request'


/**
 * 登录
 * @param code
 * @param state
 * @returns {never}
 */
export function login(code,state) {
    return request({
        url: '/api/wx/auth/login?code='+code+'&state='+state+'&remember-me=on',
        method: 'GET'
    })

}

export function manualLogin(user) {
    return request({
        url: '/api/wx/auth/manualLogin',
        method: 'POST',
        data: user
    })

}

export function existUser(openid) {
    return request({
        url: '/api/wx/auth/existUser?openid='+openid,
        method: 'POST',
        headers:{
            'Content-Type': 'application/json',
        },
        params: {
            'openid': openid
        }
    })

}

export function getOpenId(code,state){
    return request({
        url: '/api/wx/auth/redirect?code='+code+'&state='+state,
        method: 'POST'
    })
}


export function authenticate(code,state){
    return request({
        url: '/api/wx/auth/authenticate?code='+code+'&state='+state,
        method: 'POST'
    })
}

export function getSmsCode(phone) {
    return request({
        url: '/api/sms/send',
        method:'POST',
        headers:{
            'Content-Type': 'application/json',
        },
        params: {
            'phone': phone
        }
    })
}

export function checkSmsCode(phone,validation) {
    return request({
        url: '/api/apply/bindPhone',
        method:'POST',
        headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        params: {
            phone: phone,
            validation: validation
        }
    })
}

/**
 * 解除绑定
 */
export function unbind(){
    return request({
        url: '/api/user/unbind',
        method: 'POST',
        params: {}
    })

}

/**
 * 注销账号
 */
export function logOffCount(){
    return request({
        url: '/api/user/disabled',
        method: 'POST',
        params: {}
    })

}
