import router from "@/router";

export function jumpTo(path,param) {
   if (param){
       router.push({
           path: path,
           query: {
               param:param
           }
       })
   }else{
       router.push({
           path: path
       })
   }
}
