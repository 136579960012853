import request from "@/request/request";
import {showFailToast} from "vant";


//订单保存
export function saveList(applyForm) {
    return request({
        url: '/api/apply/yy/save',
        method: 'POST',
        data: applyForm,
        headers: {
            'showLoading': false
        }

    })
}



//订单提交
export function submitList(id) {
    return request({
        url: '/api/apply/submit',
        method: 'POST',
        params:{
            id:id
        }
    }).catch((err)=>{
        showFailToast(err);
    })
}


// 获取临存订单
export function getTemp() {
    return request({
        url: '/api/apply/temp',
        method: 'POST'
    })
}

//识别地址内容
export function identifyAddress(text){
    return request({
        url:'/api/autoIdentify/address',
        method:'POST',
        headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: {text:text}
    })
}
