import wx from 'weixin-js-sdk'
import request from "@/request/request";


export default {
    // 获取微信支付SDK的签名
    getWxPaySign (params) {
        console.log(params,'222222222222')
        return new Promise((resolve) => {
            // 发起请求获取签名
            request({
                url:'/api/wx/pay/getJsapiSignature',
                method:'POST',
                params:params
            }).then(res=>{
                resolve(res)
            })
        })
    },

    // JSAPI下单，获取微信支付prepay_id
    transactionJsApi(applyId){
        return new Promise((resolve) => {
            request({
                url:"/api/wx/pay/create",
                method:"POST",
                params:{
                    applyId: applyId
                }
            }).then(res=>{
                resolve(res)
            })
        })

    },


    // 发起微信支付
    wxPay (params) {
        return new Promise((resolve, reject) => {
            console.log(params,'调起微信支付的参数')
            // 调用微信支付
            wx.chooseWXPay({
                appId: params.appId,
                timestamp: params.timestamp,
                nonceStr: params.nonceStr,
                package: params.package,
                signType: params.signType,
                paySign: params.paySign,
                success: (res) => {
                    // 接口调用成功时执行的回调函数，支付成功时返回resolve
                    resolve(res)
                },
                cancel: (res) =>{
                    // 用户取消支付进入complete和cancel函数,不会进入success函数
                    resolve(res)
                },
                fail: (err) => {
                    // 接口调用失败时执行的回调函数，支付失败时返回reject
                    reject(err)
                }
            })
        })
    }
}
