import request from "@/request/request";

export function getDeatil(id){
    console.log('获取详情页数据',id)
    return request({
        url:'api/apply/detail',
        method:'POST',
        params:{
            id:id
        }
    })
}
