<template>
  <div class="flex-align-items input-style" @mouseleave="blurInput" style="padding:4px 6px;">
    <input type="text"
           :value="modelValue"
           class="inputId"
           style="height: 20px;border: none;width: 100%;"
           @focus="focusInput"
           @input="handleCompositionEnd"
    >
    <van-icon name="clear" v-if="inputShow" @click="deletInput" color="rgb(208 208 208)" size="20" style="position:absolute;top:6px;right:8px;" />
  </div>
</template>

<script>
import {ref} from "vue";
import {debounce} from "lodash";

export default {
  name: "inputView",
  props:{
    modelValue:String
  },
  setup(props,context){
    const inputShow=ref(false);
    const inputVal=ref('');
    const focusInput=()=>{
      inputShow.value=true;
      context.emit('focus', inputShow.value);
    }
    const blurInput=()=>{
      inputShow.value=false;
      context.emit('click', inputShow.value);
    }
    const deletInput=(inputVal)=>{
      context.emit('clear', inputVal);
    }

    const handleCompositionEnd=debounce((event)=>{
      context.emit("update:modelValue",event.target.value)
    },500)

    return{
      inputShow,inputVal,focusInput,blurInput,deletInput,handleCompositionEnd
    }
  }
}
</script>

<style scoped lang="scss">
.input-style{
  width: 1.8rem;
  position: relative;
  //border: 0.04rem solid rgb(208 208 208);
  font-size: 0.36rem!important;
  border: 0.02rem solid var(--van-checkbox-border-color);
}
</style>
