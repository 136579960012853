<template>
    <div></div>
</template>

<script setup>
    import {onBeforeMount} from "vue";
    onBeforeMount(()=>{
        const params = new URLSearchParams(window.location.search);
        let code = params.get('code');// 这是微信返回的code
        console.log(code)
        let state = params.get('state');// 登录成功后的参数
        console.log(state)
        let item = localStorage.getItem("wxCscyUser");
        let parse = JSON.parse(item);
        console.log(parse)
        let unionid = JSON.parse(item).unionid;
        console.log(unionid)
        let b = !state || state.indexOf("tabbar") === -1;
        console.log(b)
        if (parse && !unionid && b){
            let pathname = window.location.pathname;
            // 未授权，调起授权
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7b7c22d24bb08527&redirect_uri=https://wechat.chaxin.org.cn${pathname}&response_type=code&scope=snsapi_userinfo&state=tabbar234#wechat_redirect&forcePopup=true`
        }
    })

</script>

<style scoped>

</style>