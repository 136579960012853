import request from '@/request/request'

/**
 * 获取用户信息
 */
export function getUser() {
    return request({
        url: '/api/wx/auth/getUser',
        method: 'GET'
    })
}

/**
 * 获取我的
 */
export function getUserInfo() {
    return request({
        url: '/api/apply/count',
        method: 'GET'
    })
}
