import axios from 'axios'
import { showLoadingToast, closeToast} from 'vant';
import {showFailToast} from "vant";
import _ from 'lodash';
// import {jumpTo} from "@/api/routerJS";
//创建实例
let request = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    withCredentials: true,
    headers:{
        'showLoading': true
    },
    timeout: 100000,
    crossDomain: true
});


//loading对象
let loading;

//当前正在请求的数量
let needLoadingRequestCount = 0;

//显示loading
function showLoading() {
    // 后面这个判断很重要，因为关闭时加了抖动，此时loading对象可能还存在，
    // 但needLoadingRequestCount已经变成0.避免这种情况下会重新创建个loading
    if (needLoadingRequestCount === 0 && !loading) {
        loading = showLoadingToast({
            duration:0,
            forbidClick: true,
            message: '加载中...',
        });
    }
    needLoadingRequestCount++;
}

//隐藏loading
function hideLoading() {
    needLoadingRequestCount--;
    needLoadingRequestCount = Math.max(needLoadingRequestCount, 0); //做个保护
    if (needLoadingRequestCount === 0) {
        //关闭loading
        toHideLoading();
    }
}

//防抖：将 300ms 间隔内的关闭 loading 便合并为一次。防止连续请求时， loading闪烁的问题。
var toHideLoading = _.debounce(()=>{
    if (loading){
        loading.close();
    }
    loading = null;
}, 1000);

request.interceptors.request.use(
    (config) => {
        //判断当前请求是否设置了不显示Loading
        if(config.headers.showLoading !== false){
            showLoading(config.headers.loadingTarget);
        }
        return config;
    },
    (err) => {
        //判断当前请求是否设置了不显示Loading
        if(err.headers.showLoading !== false){
            hideLoading();
        }
        return Promise.reject(err);
    }
);

//响应拦截器
request.interceptors.response.use(
    (res) => {
        //判断当前请求是否设置了不显示Loading（不显示自然无需隐藏）
        if(res.config.headers.showLoading !== false){
            hideLoading();
        }
        if(res.data.success){
            closeToast();
        }else{
            showFailToast(`${res.data.msg}`)
        }
        return res.data;
    },
    (err) => {
        //判断当前请求是否设置了不显示Loading（不显示自然无需隐藏）
        if(err.config.headers.showLoading !== false){
            hideLoading();
        }
        if (err.response.status === 403) {
            // jumpTo('/reLogin')
            // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7b7c22d24bb08527&redirect_uri=https://wechat.chaxin.org.cn&response_type=code&scope=snsapi_base&state=#wechat_redirect`
        }else if (err.response.status === 500){
            showFailToast(`${err.response.data.msg}`)
        }
        return Promise.reject(err);
    }
);


export default request
